<template>
  <el-dialog
    title="配置应用"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="90%"
    center
    top="10vh"
  >
    <div id="bu-shu" class="content">
      <div class="c-l">
        <h4>部署资源</h4>
        <el-tabs v-model="activeName">
          <el-tab-pane label="自营应用" name="first">
            <DeployResource
              v-if="dialogVisible"
              groupName="drag"
              @change="deployListChange"
              ref="deploy-resource"
              type="app"
            />
          </el-tab-pane>
          <el-tab-pane label="aptoide应用" name="second">
            <DeployResource
              v-if="dialogVisible"
              groupName="drag"
              @change="deployListChange"
              ref="deploy-resource1"
              type="aptoi"
            />
          </el-tab-pane>
        </el-tabs>
        <!-- <DeployResource
          v-if="dialogVisible"
          groupName="drag"
          @change="deployListChange"
          ref="deploy-resource"
        /> -->
      </div>
      <div class="c-r">
        <h4>部署内容</h4>
        <draggable
          id="drag_list"
          :list="list"
          class="drag-list-list"
          group="drag"
          @change="dragChange"
          :animation="300"
          ghost-class="ghost"
          chosen-class="chosen"
        >
          <!-- <div v-for="(item, index) in list" :key="item.apkId" class="tag"> -->
          <div
            v-for="(item, index) in list"
            :key="item.packageName"
            class="tag"
          >
            <div class="t-l">
              {{ index + 1 }}
            </div>
            <div class="t-r">
              <!-- <p>{{ item.apkId }}</p> -->
              <p>{{ item.apkName }}</p>
              <!-- <p>{{ item.resName }}</p> -->
              <p>{{ item.packageName }}</p>
            </div>
            <span class="delete" @click="toDelete(item, index)">
              <i class="el-icon-delete"></i>
            </span>
          </div>
        </draggable>
        <el-row type="flex" justify="end" style="margin-top: 20px">
          <el-button type="primary" @click="save()">保存</el-button>
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button @click="toDeleteAll()">一键清空</el-button>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable";
import { selectKeywordApkList, addKeywordApkList } from "@/api/searchAlphabet";
import DeployResource from "./DeployResource";
// import DeployResource from "@/components/DeployResource";
export default {
  components: { draggable, DeployResource },
  props: { isShow: Boolean, row: Object },
  computed: {
    dialogVisible: {
      get() {
        return this.isShow;
      },
      set(val) {
        this.$emit("update:isShow", val);
      },
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.fetchList();
      } else {
        this.list = [];
      }
    },
    list() {},
  },
  data() {
    return {
      searchData: {
        page: 1,
        size: 10,
        total: 1,
      },
      list: [],
      activeName: "first",
    };
  },
  methods: {
    fetchList() {
      selectKeywordApkList({ wordConfId: this.row.id }).then((res) => {
        // this.list = res.data;
        this.list = res.data.map((item) => ({
          ...item,
          apkName: item.resName,
        }));
      });
    },
    // 左侧列表发生改变更新数据
    deployListChange(arr) {
      setTimeout(() => {
        this.list.map((item) => {
          arr.forEach((l) => {
            if (l.packageName === item.packageName) {
              this.$set(l, "notDrag", true);
            }
          });
        });
      }, 0);
    },
    // 右侧拖拽发生改变
    dragChange(row) {
      if (row.added) {
        row.added.element.resName = row.added.element.apkName;
        // const { apkId } = row.added.element;
        // this.handleNotDragClass(apkId, true);
        const { packageName } = row.added.element;
        this.handleNotDragClass(packageName, true);
      }
    },
    // 删除右侧部署内容
    toDelete(row, index) {
      const { packageName } = row;
      this.list.splice(index, 1);

      this.handleNotDragClass(packageName, false);
      // const { apkId } = row;
      // this.list.splice(index, 1);

      // this.handleNotDragClass(apkId, false);
    },
    toDeleteAll() {
      this.list.map((item) => {
        this.handleNotDragClass(item.packageName, false);
        // this.handleNotDragClass(item.apkId, false);
      });
      this.list = [];
    },
    //处理 .notDrag 类名的添加，实现左侧列表选项的是否禁用
    handleNotDragClass(id, type) {
      this.$refs["deploy-resource1"].dragList.forEach((item) => {
        if (id === item.packageName) {
          this.$set(item, "notDrag", type);
        }
      });
      this.$refs["deploy-resource"].dragList.forEach((item) => {
        // if (id === item.apkId) {
        if (id === item.packageName) {
          // item.notDrag = true;
          this.$set(item, "notDrag", type);
        }
      });
    },
    // 保存
    save() {
      // if (!this.list.length) {
      //   this.$message.warning("未部署内容");
      //   return;
      // }
      let params = {
        wordConfId: this.row.id,
        list: [],
      };
      params.list = this.list.map((item, index) => {
        item.site = index;
        item.resName = item.apkName;
        return item;
      });
      // console.log(params, 2323);
      // return;
      addKeywordApkList(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.dialogVisible = false;
          this.$parent.getDataList();
        }
      });
    },
  },
};
</script>
<style scoped>
.drag_list {
  height: 570px;
}
</style>
