import request from "@/config/serve.js";

// api-字母搜索干预列表
export function ozBlockList(data) {
  return request("post", "/searchAlphabet/queryList", data);
}
// 字母搜索干预列表新增
export function ozBlockInsert(data) {
  return request("post", "/searchAlphabet/insert", data);
}
// 字母搜索干预列表删除
export function searchAlphabetDelete(data) {
  return request("post", "/searchAlphabet/delete", data);
}
// 国际化列表
export function ozBlockDescList(data) {
  return request("post", "/searchAlphabetDesc/queryList", data);
}
// 明细列表
export function queryozBlockConfList(data) {
  return request("post", "/searchAlphabetConf/queryList", data);
}

// 页面配置详情
export function selectKeywordApkList(data) {
  return request("get", "/searchAlphabetConfDetail/pageConfigDetail", data);
}
// 保存配置
export function addKeywordApkList(data) {
  return request("post", "/searchAlphabetConfDetail/detailAdd", data);
}

// 明细列表新增
export function ozBlockConfAdd(data) {
  return request("post", "/searchAlphabetConf/insert", data);
}
// 明细列表编辑
export function ozBlockConfEdit(data) {
  return request("post", "/searchAlphabetConf/update", data);
}
// 渠道项目客户列表
export function getProJectList(data) {
  return request("post", "/mcCusChannelProject/queryList", data);
}
// 明细列表删除
export function ozBlockDelete(data) {
  return request("post", "/searchAlphabetConf/delete", data);
}
// 国际化列表删除
export function ozBlockConfDelete(data) {
  return request("post", "/searchAlphabetDesc/delete", data);
}
// 国际化列表新增
export function ozBlockConfInsert(data) {
  return request("post", "/searchAlphabetDesc/insert", data);
}
// 国际化列表修改
export function ozBlockConfUpdate(data) {
  return request("post", "/searchAlphabetDesc/update", data);
}
// 市场版本数据列表
export function getMarketList(params) {
  return request("post", "/market/manage/queryList", params, false);
}
