<template>
  <div>
    <el-row :gutter="6">
      <el-col :span="24">
        <el-form :inline="true" label-suffix=":">
          <el-form-item label="字母" class="demo-form-inline headLabel"
            ><el-input
              size="small"
              v-model="queryModule.word"
              clearable
              placeholder="请输入字母"
              @keydown.native.enter="search()"
            ></el-input>
          </el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="search()"
            >查询</el-button
          >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            v-has="'add'"
            @click="add()"
            >新增</el-button
          >
        </el-form></el-col
      >
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="{ ...queryModule, list }"
      @page-change="handleChangePage"
    >
      <el-table-column fixed="right" label="操作" width="180px" align="center">
        <template slot-scope="{ row }">
          <!-- <el-button
            type="text"
            v-has="'edit'"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          > -->
          <el-button
            type="text"
            v-has="'language'"
            @click="handleOperate('internation', row)"
            >国际化</el-button
          >
          <el-button
            type="text"
            v-has="'detail'"
            @click="handleOperate('config', row)"
            >明细</el-button
          >
          <el-button
            type="text"
            v-has="'delete'"
            @click="handleClickOnOff(row)"
          >
            <span style="color: red">删除</span>
          </el-button>
        </template>
      </el-table-column>
    </PageTable>
    <!--  新增/编辑 弹框 -->
    <el-dialog
      center
      width="700px"
      :wrapperClosable="false"
      :title="operationType == 'create' ? '新增' : '编辑'"
      :visible.sync="editorDialogShow"
      :close-on-click-modal="false"
    >
      <Detail
        :operationType="operationType"
        :detailData="detailData"
        @submit="handleSubmit"
        @cancel="editorDialogShow = false"
      />
    </el-dialog>
    <!-- 国际化弹窗 -->
    <el-dialog
      center
      width="700px"
      :wrapperClosable="false"
      title="国际化"
      :visible.sync="internationDialogShow"
      :close-on-click-modal="false"
    >
      <Internation
        :detailData="detailData"
        :dialogAuditVisible="internationDialogShow"
        @submit="handleSubmit"
        @cancel="editorDialogShow = false"
      />
    </el-dialog>
    <!-- 明细弹窗 -->
    <el-dialog
      center
      width="70%"
      :wrapperClosable="false"
      title="明细"
      :visible.sync="configDialogShow"
      :close-on-click-modal="false"
    >
      <ConfigDetail
        :detailData="detailData"
        :dialogAuditVisible="configDialogShow"
        @submit="handleSubmit"
        @cancel="editorDialogShow = false"
      />
    </el-dialog>
    <DetailsDialog :isShow.sync="detailsDialogShow" :row="detailData" />
  </div>
</template>

<script>
import PageTable from "@/components/PageTable.vue";
import Detail from "./compoments/Detail.vue";
import Internation from "./compoments/Internation.vue";
import ConfigDetail from "./compoments/ConfigDetail.vue";
import DetailsDialog from "./compoments/DetailsDialog.vue";
// import { updateWishById } from "@/api/wishlist";
import {
  ozBlockList,
  ozBlockInsert,
  searchAlphabetDelete,
} from "@/api/searchAlphabet";
import { mapGetters } from "vuex";
import { wishStatus, styleList } from "@/common/constant/constant";
export default {
  name: "wishListManage",
  components: {
    PageTable,
    DetailsDialog,
    Detail,
    Internation,
    ConfigDetail,
  },
  computed: {
    ...mapGetters(["languageList"]),
  },
  watch: {
    "queryModule.language"() {
      this.search();
    },
    "queryModule.wishStatus"() {
      this.search();
    },
  },
  data() {
    return {
      wishStatus: wishStatus,
      styleList,
      columns: [
        {
          key: "id",
          label: "ID",
          align: "center",
          width: 100,
        },
        { key: "word", label: "字母", align: "center" },
        { key: "createTime", label: "创建时间", align: "center" },
        { key: "updateTime", label: "修改时间", align: "center" },
      ],
      queryModule: {
        count: true,
        page: 1,
        size: 10,
        total: 0,
        word: "",
      },
      list: [],
      operationType: "",
      relevanceDialogShow: false,
      editorDialogShow: false,
      detailsDialogShow: false,
      internationDialogShow: false,
      configDialogShow: false,
      viewImgShow: false,
      imgList: [],
      detailData: {
        word: "",
      },
    };
  },
  created() {
    this.$store.dispatch("updateLanguageList");
    this.getDataList();
  },
  methods: {
    handleSubmit(params) {
      ozBlockInsert(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("新增成功");
          this.editorDialogShow = false;
          this.getDataList();
        }
      });
    },
    search() {
      this.queryModule.page = 1;
      this.getDataList();
    },
    add() {
      this.detailData = {};
      this.operationType = "create";
      this.editorDialogShow = true;
    },
    handleChangePage({ page, size }) {
      this.queryModule.page = page;
      this.queryModule.size = size;
      this.getDataList();
    },
    getDataList() {
      ozBlockList(this.queryModule).then((res) => {
        const { list = [], total } = res.data;
        this.list = list;
        this.queryModule.total = total;
      });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      if (operationType === "edit") {
        this.editorDialogShow = true;
        this.detailData = { ...row };
      }
      if (operationType === "internation") {
        this.internationDialogShow = true;
        this.detailData = { ...row };
      }
      if (operationType === "config") {
        this.configDialogShow = true;
        this.detailData = { ...row };
      }
      if (operationType === "details") {
        this.detailsDialogShow = true;
        this.detailData = { ...row };
      }
    },
    handleClickOnOff(row) {
      const desc = `确定删除吗？`;
      this.$confirm(desc, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          searchAlphabetDelete({ id: row.id }).then((res) => {
            if (res.code == 0) {
              this.$message.success("删除成功");
              this.getDataList(); // 成功后刷新列表
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.view-el-img {
  position: relative;
  top: 5px;
  img {
    width: 25px;
    height: 20px;
    opacity: 0;
  }
  &::before {
    cursor: pointer;
    pointer-events: none;
    content: "查看";
    font-size: 12px;
    color: #409eff;
    position: absolute;
    top: 0;
  }
}
.el-dialog {
  max-height: 81vh;
  overflow: auto;
}
</style>
